<template>
  <main class="main">
    <header class="section_page">    
        <h1>Digital Modeling of Health Information</h1>
        <p class="text-mid-s">Electronic Medical Record Design, Academic Project</p>
        <section class="project-overview">
          <p>For my term project for degree, I chose the problem space of Electronic Health Records in India.</p>
          <p>The health sector is rapidly changing with the onslaught of newer systems, devices, specialized care, and cloud-based databases. And yet the adoption of Information Technology (IT) in this sector seems significantly less than others like banking, railway reservations, public service delivery, etc.</p>
          <p>The goal was a holistic understanding of the health technology to create a user-centered design of an electronic medical record.</p>
        </section>
        <div class="banner" alt="project thumbnail">
          <img src="@/assets/images/projects/cpfa/banner.jpg" />
        </div>
    </header>
    <div class="section_page">
      <section class="project-section " id="ehr-problem">
        <header>
              <h2>User Problem & Opportunity</h2>
              <p class="text-mid">Unleveraged Technology in Medical Encounters</p>
        </header>
        <div class="project-text">
          <p>India has a mixed system of healthcare consisting of a large number of hospitals run by the Central Government and State Government as well as the private sector.</p>
          <p>Apart from hospitals, there are sub-center, community-center or primary-health center in rural setups, or a general practitioner in their own clinic, to a government /private clinic at the district, city, state or central level.</p>
          <p>A single patient visits several of these health providers throughout their life span. Health records, mostly paper, get generated with every clinical encounter during these ambulatory, inpatient or emergency visits.</p>
          <p>However, most health records are either lost, or remain in the custody of health care providers and eventually get destroyed,as per the retention period of medical records generally followed by hospitals in the country i.e. for 5 years for out-patient records, and 10 years for in patient records. (This is applicable to health care setups with a proper medical record department only. There may be negligible to no health records maintained by private practitioners, at clinic setups and by rural healthcare setups).</p>
          <p>A typical Indian patient with varying literacy and awareness level usually does not retain his clinical documents either. What the patient carries with him is the discharge summary of his clinical encounter along with his investigation reports and usually radiology films/images.</p>
          <p>Some corporate hospitals have undertaken crucial initiatives in the digital health space and have built in-house software tailored to their needs. These EMRs are restricted to the hospital chain, and referenced when a patient revisits. </p>
          <p>Currently, apart from the recent/available medical records which the patient may carry, <strong>a critical source of information during a patient encounter is the patient or their attendant orally conveying their history.</strong></p>
          <p><strong>Electronic Medical Record (EMR) or Electronic Health Record (EHR) are far from the norm.</strong></p>
        </div>
      </section>
      <section class="project-section " id="ehr-goal">
        <header>
              <h2>Goal & Scope</h2>
              <p class="text-mid">A Scalable EMR</p>
        </header>
        <div class="project-text">
          <p>The aim was to approach the problem from user-centered and information-processing perspective.</p>
          <p>An Electronic Health Record (EHR) technically is used for a <strong>comprehensive</strong> electronic record of health-related information on an individual that <strong>conforms to nationally recognized interoperability standards</strong> and that can be created, managed, and consulted by authorized clinicians and staff <strong>across more than one health care organization</strong>.</p>
          <p>For a functional EHR, there are multiple user groups -  doctors, patients, care-givers, lab technicians, pharmacists, administrators, hospital chains, researchers. And with this comes the functional, legal and security design concerns.</p>
          <img src="@/assets/images/projects/ehr/levels.png" />
          <p>As this was too large of a scope, I decided to limit the design to EMR, but with complete consideration of the interconnected requirements, and scalability in future.</p>        
        </div>
      </section>
      <section class="project-section " id="ehr-research">
        <header>
              <h2>Research</h2>
              <p class="text-mid">Gaining Domain Knowledge</p>
        </header>
        <div class="project-text">
          <div class="columns">
            <div class="chunk">
              <h3>Objective</h3>        
              <ul>
                <li>Gain an understanding of  physicians', nurses', etc. attitude towards EMRs and their experience with any.</li>
                <li>Conduct an ethnographic observation to analyze the environment, artifacts (including existing EMRs) and specific work settings (outpatient and ICU patients) in which user tasks were performed.</li>           
              </ul>
            </div>
            <div class="chunk">
              <h3>Methodology</h3>        
              <ul>
                <li>Contextual Inquiry (large private hospital - name removed per request)</li>
                <li>User Interviews</li>
                <li>Artifact analysis</li>
                <li>Secondary Research (Literature Review)</li>            
              </ul>
            </div>
            <div class="chunk">
              <h3>Participants</h3>        
              <ul>
                <li>3 Doctors at the hospital at IIT Guwahati</li>
                <li>2 Doctors at the private hospital</li>
                <li>IT Head at the private hospital</li>                         
              </ul>
              <p><em>I conducted a semi-structured 8 students and staff who were undergoing treatment or had recently visited (outpatient or admitted) at IIT Guwahati Hospital.</em></p>
            </div>
            <div class="chunk">
              <h3>Secondary Research</h3>        
              <ul>
                <li>Studies on EMR and EHR designs, strategies of adoption across countries (in both public & privatized sectors), barriers to adoption, usability issues, the standards and guidelines for Electronic Health Records in India and other countries.</li>
                <li>Studies on IT design and acceptance in other domains (banking, public service, etc.)</li>
                <li>Common  and best practices of designing structuring qualitative data (ex: SOAP for encounter record), grouping information (ex: by doctor, diagnosis, event, time), deciding on meta-data, etc. in EHR.</li>                        
              </ul>
            </div>
          </div>
          <img src="@/assets/images/projects/ehr/literature_review.webp" alt="impact diagram created from research data" />
        </div>
      </section>
      <section class="project-section " id="ehr-findings">
        <header>
              <h2>Findings</h2>
              <p class="text-mid">Current Situation and Issues</p>
        </header>
        <div class="project-text">
          <div class="columns">
            <div class="chunk">
              <h3>EMRs are just digital vaults</h3>        
              <p>Current designs are mostly digitally recreated versions of paper records with no additional value. While all doctors, nurses think EHR will increase better record-keeping, most(3) don't think it will increase their efficiency. On the other hand, <strong>most doctors are skeptical of Clinical Decision Support System (CDSS).</strong></p>
              <p>Doctors are also wary of data from patient wearables and not yet use it as a verified source of data. Patients disagree.</p>
            </div>
            <div class="chunk">
              <h3>Usability and User Experience Issues</h3>    
              <p>Most available software are riddled with complex navigation, bloated design, and unused options. Available information were scattered across sections and there is no holistic view. Records are stored in cumbersome heavy pdf mode.</p>
              <p>A work-around for the mandatory documentation has been recording on paper and later updating into the system. This is additional work.</p>
            </div>          
            <div class="chunk">
              <h3>EMRs affect the doctor-patient trust and engagement</h3>    
              <p>The engagement and rapport with the patient is important to get the patient to be open and freely share detailed information to assist in the diagnosis. Patient's assessment and trust of the doctor is determined by how the doctor engages with them. The intensive data-entry in EHR <strong>could be perceived by patients as less attention to their description of their condition.</strong></p>            
            </div>
          <div class="chunk">
              <h3>Access for patients</h3>    
              <p>Doctors didn't see much value or didn't have opinion on patient's access to the data. On probing, they said that patient should be given a view-only option of their data. Patients believe having access to their data, especially in temporal visualization form would better help them to trust their doctors.</p>            
            </div>
          </div>        
        </div>
      </section>
      <section class="project-section " id="ehr-persona">
        <header>
              <h2>Persona</h2>
              <p class="text-mid">Technologically Literate Physician</p>
        </header>
        <div class="project-text">
          <img src="@/assets/images/projects/ehr/persona.png" alt="persona:Arindam, 36 year physician with own clinic wanting to provide best possible care to his patients" />		
        </div>
      </section>
      <section class="project-section " id="ehr-objectives">
        <header>
              <h2>Design Objectives</h2>
              <p class="text-mid">From Data Vault to Assistive Tool</p>
        </header>
        <div class="project-text">
          <ul>
            <li><strong>Intuitive, streamlined workflows:</strong> Aligning the product with the existing encounter behavior - more focus on the interaction with the patient and natural data collection flow.</li>
            <li><strong>Structured scalable data format:</strong> To be easily readable and understandable by all users in the organization (and later health information exchange (HIE) across organizations), subjective data collection was constrained to universal and common.</li>
            <li><strong>Access Control:</strong> With security being a being concern for both patients and doctors, a proper access control is a must. Moreover, this is an essential requirement for compliance tracking in organizations.</li>
            <li><strong>Reduced effort and clicks (automation), better error handling (intelligence), intelligent safeguards</strong></li>          
            <li><strong>Digestible patient journey:</strong> A patient story in the EHR provides clinicians with important clinical information, such as the patient’s problem list, active care plans, expected discharge date, safe patient handling and mobility information and more.<span class="break">As user research puts the user at the center of design, a patient journey puts the patient at the center of a doctor's flow.</span></li>
          </ul>				
        </div>
        
      </section>
      <section class="project-section " id="ehr-process">
        <header>
            <h2>Process</h2>
            <p class="text-mid">Data Modelling & Wireframes</p>
        </header>
        <div class="project-text">
          <p><strong>Use case diagrams, activity diagram and finally class-diagrams</strong> were systematically created to define the structure of the system.</p>
          <p>The <strong>information architecture</strong> was derived from class diagrams and the connections.</p>
          <p>Data and technological assumptions:</p>
          <ul>
            <li><i>Existing database of diagnosis, medicines, tests etc. to automate doctor's tasks and free cognitive resources wherever possible</i></li>
            <li><i>Clinical Decision Support System (CDSS) and artificial intelligence to guide clinical diagnosis and decision making</i></li>
          </ul>
          <img src="@/assets/images/projects/ehr/modelling.svg" />
          <p><em>The format chosen was web-app to leverage the latest technologies as well as to have consistent experience across all devices. With desktops and monitors being the most common devices, that was the chosen device to design for. (While tablets are used in some hospitals, it isn't common)</em></p>
          <p>Pen sketches helped form the initial idea of the interface on phones, tablets and desktops. The finalized design was digitized into mid-fidelity wireframes.</p>
          <img src="@/assets/images/projects/ehr/wireframe.svg" />
        </div>
      </section>
      <section class="project-section " id="ehr-learnings">
        <header>
              <h2>Learnings</h2>
              <p class="text-mid">Planning and modelling system architecture</p>
        </header>
        <div class="project-text">
          <ul>
            <li>System design</li>
            <li>Scalable design system</li>
            <li>Rich Application Design Patterns</li>
            <li>Approaching and handling scope creep</li>
            <li>Unified Modeling Language (UML)</li>
            <li>Data visualization</li>
            <li>Designing for interconnected stakeholder requirements</li>
          </ul>
        </div>
      </section>
    </div>
  </main>
</template>
<style scoped>
@media only screen and (min-width: 60rem) {
  .columns{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.1rem;
  background: var(--grey-base);
  }
  .chunk{
    background: white;
    padding: var(--padding-lg)
  }
  .chunk li{

  }
}
</style>

